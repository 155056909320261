export const DashboardFillIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <path
        id="_-Color"
        data-name="🎨-Color"
        d="M18.25,3A2.75,2.75,0,0,1,21,5.747v12.5A2.75,2.75,0,0,1,18.25,21H5.75A2.75,2.75,0,0,1,3,18.247V5.747A2.75,2.75,0,0,1,5.75,3ZM7.75,9A.748.748,0,0,0,7,9.747v6.506a.75.75,0,0,0,1.5,0V9.747A.748.748,0,0,0,7.75,9Zm8.5-2a.74.74,0,0,0-.75.729v8.541a.75.75,0,0,0,1.5,0V7.729A.74.74,0,0,0,16.25,7Zm-4.275,5a.729.729,0,0,0-.725.734l.035,3.547a.732.732,0,0,0,1.465-.014l-.035-3.547A.73.73,0,0,0,11.975,12Z"
        transform="translate(-3 -2.997)"
        fill="#048cf2"
      />
    </svg>
  );
};
