export const DashboardIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <g
        id="_-Product-Icons"
        data-name="🔍-Product-Icons"
        transform="translate(-3 -2.997)"
      >
        <g id="ic_fluent_data_usage_24_regular">
          <path
            id="_Color"
            data-name="🎨Color"
            d="M18.25,3A2.75,2.75,0,0,1,21,5.747v12.5A2.75,2.75,0,0,1,18.25,21H5.75A2.75,2.75,0,0,1,3,18.247V5.747A2.75,2.75,0,0,1,5.75,3Zm0,1.5H5.75A1.25,1.25,0,0,0,4.5,5.747v12.5A1.25,1.25,0,0,0,5.75,19.5h12.5a1.25,1.25,0,0,0,1.25-1.25V5.747A1.25,1.25,0,0,0,18.25,4.5ZM7.75,9a.749.749,0,0,1,.743.645l.007.1v6.506a.75.75,0,0,1-1.493.1L7,16.253V9.747A.748.748,0,0,1,7.75,9Zm8.5-2a.744.744,0,0,1,.743.63l.007.1v8.541a.752.752,0,0,1-1.493.1l-.007-.1V7.729A.74.74,0,0,1,16.25,7Zm-4.275,5a.731.731,0,0,1,.732.621l.008.1.035,3.547a.733.733,0,0,1-1.457.113l-.008-.1L11.25,12.73A.729.729,0,0,1,11.975,12Z"
            fill="#048cf2"
          />
        </g>
      </g>
    </svg>
  );
};
