import { PropsWithChildren, useEffect, useState } from 'react';
import { ExpandLessIcon } from 'pages/β_layputs/icons/ExpandLessIcon';
import { ExpandMoreIcon } from 'pages/β_layputs/icons/ExpandMoreIcon';
import { StoreIcon } from 'pages/β_layputs/icons/StoreIcon';
import { FlagIcon } from 'pages/β_layputs/icons/FlagIcon';
import { PeopleIcon } from 'pages/β_layputs/icons/PeopleIcon';
import { MandalatIcon } from 'pages/β_layputs/icons/MandalatIcon';
import { SettingsIcon } from 'pages/β_layputs/icons/SettingsIcon';
import { StarIcon } from 'pages/β_layputs/icons/StarIcon';
import { BusinessIcon } from 'pages/β_layputs/icons/BusinessIcon';
import { HomeIcon } from 'pages/β_layputs/icons/HomeIcon';
import { useNavigate } from 'react-router-dom';

import * as BrandsRoutes from 'pages/01_shops/01_brands_routes';
import * as ShopsRoutes from 'pages/01_shops/02_shops_routes';
import * as ShopsMboRoutes from 'pages/02_shop_mbo/routes';
import * as StaffsRoutes from 'pages/03_staffs/routes';
import * as MandalatRoutes from 'pages/04_mandalat/routes';
import * as OccupationPositionSettingRoutes from 'pages/05_operation-setting/01_occupation-position-setting_routes';
import * as GradeListRoutes from 'pages/05_operation-setting/02_grade_routes';
import * as CareerPathSettingRoute from 'pages/05_operation-setting/03_career-path-setting_routes';
import * as EvaluationMeasureSettingRoutes from 'pages/05_operation-setting/04_evaluation-measure-setting_routes';
import * as EvaluationPeriodSettingRoute from 'pages/05_operation-setting/05_evaluation-period-setting_routes';
import * as OutsideManagerListRoutes from 'pages/05_operation-setting/06_outside-managers_routes';
import * as MvpCategorySettingListRoute from 'pages/05_operation-setting/07_mvp-category-setting_routes';
import * as ObjectiveRecordsUserTypeRoute from 'pages/06_objective-records/02_ObjectiveRecordsRoute';
import * as CorporationRankingsRoute from 'pages/06_objective-records/01_CorporationRankingsRoute';
import * as ContractSettingRoute from 'pages/07_operation-setting/01_contract-setting_routes';
import * as CorporationAdminListRoute from 'pages/07_operation-setting/02_corporation-admins_routes';
import LogoImage from './icons/logo_vertical.png';
import { LogoSimpleIcon } from './icons/LogoSimpleIcon';
import { LinkButton } from './_components/LinkButton';
import { DashboardIcon } from './icons/DashboardIcon';
import { DashboardFillIcon } from './icons/DashboardFillIcon';

const ExpandIcon = (props: { isOpen: boolean }) => {
  return (
    <div style={{ position: 'absolute', right: '16px' }}>
      {props.isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
    </div>
  );
};

const Single = (props: any) => {
  const navigate = useNavigate();
  const activeStyle = getActiveStyle(props);

  if (props.isExpanded) {
    return (
      <button
        className="base-hover px-[20px] py-[15px] text-base w-full"
        style={activeStyle}
        type="button"
      >
        <div className="flex items-center">
          <p className="mr-[20px]">{props.icon}</p>
          <p
            onClick={() => {
              navigate(props.path);
            }}
          >
            {props.name}
          </p>
        </div>
      </button>
    );
  }

  return (
    <div
      className="hover:bg-grayscale-100 hover:bg-opacity-10 cursor-pointer"
      style={activeStyle}
    >
      <div className="flex items-center">
        <p
          className="p-[20px]"
          onClick={() => {
            navigate(props.path);
          }}
        >
          {props.icon}
        </p>
      </div>
    </div>
  );
};

function getActiveStyle(props: any) {
  let isCurrentPath = window.location.pathname === props.path;
  if (!isCurrentPath) {
    if (props.path !== '/') {
      isCurrentPath = window.location.pathname.startsWith(props.path);
    }
  }

  return isCurrentPath ? { backgroundColor: 'rgba(255, 255, 255, 0.1)' } : {};
}

const Expand = (props: any) => {
  const [isOpenMenu, setIsOpenMenu] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (!props.isExpanded) {
      setIsOpenMenu(false);
    }

    if (props.isExpanded) {
      setIsOpenMenu(true);
    }
  }, [props.isExpanded]);

  if (props.isExpanded) {
    return (
      <div>
        <button
          className="flex items-center py-[15px] px-[20px] text-base relative w-full"
          onClick={() => {
            setIsOpenMenu(!isOpenMenu);
          }}
          type="button"
        >
          <p className="mr-[20px]">{props.icon}</p>
          <p>{props.name}</p>
          <ExpandIcon isOpen={isOpenMenu} />
        </button>
        {isOpenMenu && (
          <div>
            {props.list.map((item: any) => {
              const activeStyle = getActiveStyle({
                path: item.path,
              });

              return (
                <p
                  className="pl-[60px] h-[36px] base-hover text-meta flex items-center"
                  style={activeStyle}
                  onClick={() => {
                    navigate(item.path);
                  }}
                >
                  {item.name}
                </p>
              );
            })}
          </div>
        )}
      </div>
    );
  }

  const hoverStyle = 'bg-grayscale-100 bg-opacity-10';
  let activeStyled = {};
  props.list.forEach((item: any) => {
    if (Object.keys(getActiveStyle({ path: item.path })).length) {
      activeStyled = getActiveStyle({ path: item.path });
    }
  });

  return (
    <div
      className="relative"
      onMouseEnter={() => {
        setIsOpenMenu(true);
      }}
      onMouseLeave={() => {
        setIsOpenMenu(false);
      }}
    >
      <button
        className={`cursor-pointer flex items-center text-base ${
          isOpenMenu ? hoverStyle : ''
        }`}
        style={activeStyled}
        type="button"
      >
        <p className="p-[20px] min-w-[60px] min-h-[60px] max-w-[60px] max-h-[60px]">
          {props.icon}
        </p>
      </button>
      {isOpenMenu && (
        <div
          className="
        absolute left-[60px] top-[0px] w-[200px] bg-grayscale-800 text-grayscale-700 z-[50px]
        "
        >
          <p
            className={`
          text-base text-grayscale-100
          p-[20px] py-[20px] hover:bg-grayscale-100 hover:bg-opacity-10 ${
            isOpenMenu ? hoverStyle : ''
          }`}
          >
            {props.name}
          </p>
          {props.list.map((item: any) => {
            const activeStyle = getActiveStyle({
              path: item.path,
            });

            return (
              <p
                className={`
              text-meta hover:text-grayscale-100 cursor-pointer
               p-[20px] py-[12px] hover:bg-grayscale-100 hover:bg-opacity-10
               ${Object.keys(activeStyle).length ? 'text-grayscale-100' : ''}
            `}
                onClick={() => {
                  navigate(item.path);
                }}
              >
                {item.name}
              </p>
            );
          })}
        </div>
      )}
    </div>
  );
};
export const MenuDrawer = (
  /* eslint-disable @typescript-eslint/no-unused-vars */
  props: PropsWithChildren<{
    expandSidebarState: boolean;
  }>
) => {
  const isExpanded = props.expandSidebarState;

  const width = isExpanded ? 'min-w-[260px]' : 'min-w-[60px]';

  return (
    <div
      className={`bg-grayscale-800 text-grayscale-100 pt-[18px] sticky top-0  h-screen ${width} z-[50]`}
    >
      <div>
        {isExpanded && (
          <img
            src={LogoImage}
            className="h-[26px] mb-[15px] ml-[11px]"
            alt="ロゴ"
          />
        )}
        {!isExpanded && (
          <p className="px-[10px]">
            <LogoSimpleIcon />
          </p>
        )}
      </div>

      <div
        className={`${
          isExpanded ? 'overflow-y-scroll' : ''
        } h-[calc(100vh-60px)] pb-[130px]`}
      >
        <Single
          name="ホーム"
          path="/"
          icon={<HomeIcon />}
          isExpanded={isExpanded}
        />
        <Expand
          name="店舗管理"
          list={[
            { name: '業態一覧', path: BrandsRoutes.BrandListRoute.path },
            { name: '店舗一覧', path: ShopsRoutes.ShopListRoute.path },
          ]}
          icon={<StoreIcon />}
          isExpanded={isExpanded}
        />
        <Single
          name="店舗MBO管理"
          path={ShopsMboRoutes.ShopMboListRoute.path}
          icon={<FlagIcon />}
          isExpanded={isExpanded}
        />
        <Expand
          name="スタッフ管理"
          list={[
            { name: 'スタッフ一覧', path: StaffsRoutes.StaffListRoute.path },
            {
              name: '退職者一覧',
              path: StaffsRoutes.UnsubscribedStaffListRoute.path,
            },
          ]}
          icon={<PeopleIcon />}
          isExpanded={isExpanded}
        />
        <Single
          name="マンダラ設定"
          path={MandalatRoutes.ImportMandalatMasterCsvRoute.path}
          icon={<MandalatIcon />}
          isExpanded={isExpanded}
        />
        <Expand
          name="運用設定"
          list={[
            {
              name: '評価スケジュール設定',
              path: EvaluationPeriodSettingRoute.EvaluationPeriodSettingRoute
                .path,
            },
            {
              name: '職種・職位・等級設定',
              path: OccupationPositionSettingRoutes
                .OccupationPositionSettingRoute.path,
            },
            {
              name: '給与 / 評価ウェイト設定',
              path: GradeListRoutes.GradeListRoute.path,
            },
            {
              name: 'キャリアパス設定',
              path: CareerPathSettingRoute.CareerPathSettingRoute.path,
            },
            {
              name: '評価尺度設定',
              path: EvaluationMeasureSettingRoutes.EvaluationMeasureSettingRoute
                .path,
            },
            {
              name: 'マネジャー評価者設定',
              path: OutsideManagerListRoutes.OutsideManagerListRoute.path,
            },
            {
              name: 'MVPカテゴリ設定',
              path: MvpCategorySettingListRoute.MvpCategorySettingListRoute
                .path,
            },
          ]}
          icon={<SettingsIcon />}
          isExpanded={isExpanded}
        />
        <Expand
          name="評価情報"
          list={[
            {
              name: '全社ランキング',
              path: CorporationRankingsRoute.CorporationRankingsRoute.path,
            },
            {
              name: 'スタッフ最終評価完了一覧',
              path: ObjectiveRecordsUserTypeRoute.ObjectiveRecordsUserTypeRoute
                .staffPath,
            },
            {
              name: 'マネジャー最終評価完了一覧',
              path: ObjectiveRecordsUserTypeRoute.ObjectiveRecordsUserTypeRoute
                .managerPath,
            },
          ]}
          icon={<StarIcon />}
          isExpanded={isExpanded}
        />
        <Expand
          name="企業情報"
          list={[
            {
              name: 'ご契約情報',
              path: ContractSettingRoute.ContractSettingRoute.path,
            },
            {
              name: '担当者情報',
              path: CorporationAdminListRoute.CorporationAdminListRoute.path,
            },
          ]}
          icon={<BusinessIcon />}
          isExpanded={isExpanded}
        />

        <div className="mt-[20px]">
          <LinkButton
            icon={<DashboardIcon />}
            iconHover={<DashboardFillIcon />}
            label="ダッシュボード"
            isShowLabel={isExpanded}
            linkUrl={process.env.REACT_APP_URL_DASHBOARD as string}
            target="dashboard"
          />
        </div>
      </div>
    </div>
  );
};
