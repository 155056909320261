import { Link } from 'react-router-dom';

export const LinkButton = ({
  label,
  linkUrl,
  icon,
  iconHover,
  isShowLabel,
  target,
}: {
  label: string;
  linkUrl: string;
  icon: JSX.Element;
  iconHover?: JSX.Element;
  isShowLabel?: boolean;
  target?: string;
}) => {
  return (
    <div className={`${isShowLabel ? 'px-[20px]' : 'px-[10px]'}`}>
      <Link
        to={linkUrl}
        target={target ?? '_blank'}
        className={`group
        w-full flex gap-[20px] bg-grayscale-100 rounded
        ${
          isShowLabel
            ? 'px-[20px] py-[15px] justify-start'
            : 'p-[10px] justify-center'
        }
      `}
      >
        <div className={`block ${iconHover && 'group-hover:hidden'}`}>
          {icon}
        </div>
        {iconHover && (
          <div className="hidden group-hover:block">{iconHover}</div>
        )}
        {isShowLabel && (
          <div className="text-base text-grayscale-800 font-bold group-hover:text-main-blue">
            {label}
          </div>
        )}
      </Link>
    </div>
  );
};
